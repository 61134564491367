<template>
    <div>
        <div class="mb-3">
            <div class="row">
                <div class="col-6">
                    <h4>{{$t('msc.welcome')}}</h4>
                </div>
                <div class="col-6 text-right text-uppercase">
                    <h4>{{time}}</h4>
                </div>
            </div>
        </div>
        <notifications></notifications>
        <template v-if="role === 'suppliercarrier'">
            <carrier-supplier></carrier-supplier>
        </template>
        <template v-else-if="role === 'superadmin'">
            <superadmin></superadmin>
        </template>
        <template v-else-if="role === 'efl'">
            <efl></efl>
        </template>
        <template v-else-if="role === 'driver'">
            <driver></driver>
        </template>
        <template v-else-if="role === 'client'">
            <client></client>
        </template>
        <template v-else-if="role === 'dealer'">
            <dealer></dealer>
        </template>
    </div>
</template>
<script>
    import {getAuthUser, hasAuthUser} from "../../util/Utils";
    import superadmin from './superadmin'
    import efl from './efl'
    import carrierSupplier from './suppliers/carrierSupplier'
    import driver from './driver'
    import client from './client'
    import dealer from './dealer'
    import moment from 'moment-timezone'

    export default {
        components: {
            superadmin,
            efl,
            carrierSupplier,
            driver,
            client,
            dealer,
        },
        data() {
            return {
                role: 'unknown'
            }
        },
        mounted() {
            if (hasAuthUser()) {
                this.setActiveRole()
            }

            this.$title = this.$t('topBar.navigations.dashboards')
        },
        methods: {
            setActiveRole() {
                const {roles} = getAuthUser();
                if (_.findIndex(roles, {slug: 'superadmin'}) >= 0) {
                    this.role = 'superadmin'
                }
                if (_.findIndex(roles, {slug: 'efl'}) >= 0) {
                    this.role = 'efl'
                }
                if (_.findIndex(roles, {slug: 'suppliercarrier'}) >= 0) {
                    this.role = 'suppliercarrier'
                }
                if (_.findIndex(roles, {slug: 'driver'}) >= 0) {
                    this.role = 'driver'
                }
                if (_.findIndex(roles, {slug: 'client'}) >= 0) {
                    this.role = 'client'
                }
                if (_.findIndex(roles, {slug: 'dealer'}) >= 0) {
                    this.role = 'dealer'
                }
            }
        },
        computed: {
            time() {
                return moment.tz(moment.tz.guess()).format('DD.MM.YYYY ddd')
            }
        }
    }
</script>
