<template>
    <div>
        <b-row>
            <b-col sm="6">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.inbound')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 Inbound Loads requests</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.loadNumber')}}</th>
                                    <th>{{$t('column.loadingPoint')}}</th>
                                    <th>{{$t('column.unLoadingPoint')}}</th>
                                    <th>{{$t('column.status')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(il, index) in inbound_loads">
                                    <td>{{il.load_number}}</td>
                                    <td>
                                        <print-ad titled="1" :item="il.from_location"></print-ad>
                                    </td>
                                    <td>
                                        <print-ad titled="1" :item="il.to_location"></print-ad>
                                    </td>
                                    <td>
                                        <ibl-status :status="il.status"></ibl-status>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col sm="6">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.outbound')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 Outbound Loads requests</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.loadNumber')}}</th>
                                    <th>{{$t('column.loadingPoint')}}</th>
                                    <th>{{$t('column.unLoadingPoint')}}</th>
                                    <th>{{$t('column.status')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(ol, index) in outbound_loads">
                                    <td>{{ol.load_number}}</td>
                                    <td>
                                       <print-ad titled="1" :item="ol.from_location"></print-ad>
                                    </td>
                                    <td>
                                        <print-ad titled="1" :item="ol.to_location"></print-ad>
                                    </td>
                                    <td>
                                        <ibl-status :status="ol.status"></ibl-status>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="6">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.vehicleArrivals')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 {{$t('title.vehicleArrivals')}}</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.loadNumber')}}</th>
                                    <th>{{$t('column.loadingPoint')}}</th>
                                    <th>{{$t('column.unLoadingPoint')}}</th>
                                    <th>{{$t('column.status')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(val, index) in verhicle_arrival_list">
                                    <td>{{val.load_number}}</td>
                                    <td>
                                        <span v-for="(olo,index) in val.dealer_to_dealer_load_orders">
                                            <span v-if="olo.order && index<= 0">
                                                <span v-if="olo.from_location">
                                                    {{olo.from_location.city}}
                                                </span>
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        <span v-for="(olo,index) in val.dealer_to_dealer_load_orders">
                                            <span v-if="olo.order && index<= 0">
                                                <span v-if="olo.to_location">
                                                    {{olo.to_location.city}}
                                                </span>
                                            </span>
                                        </span>
                                    </td>
                                    <td>
                                        <obl-status :status="val.status"></obl-status>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col sm="6">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.deliveryRequests')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 {{$t('title.deliveryRequests')}}</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.orderId')}}</th>
                                    <th>{{$t('column.vin')}}</th>
                                    <th>{{$t('column.loadingPoint')}}</th>
                                    <th>{{$t('column.unLoadingPoint')}}</th>
                                    <th>{{$t('column.status')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(dr, index) in deliver_request">
                                    <td>{{dr.order_id}}</td>
                                    <td>{{dr.vin_number}}</td>
                                    <td>
                                        <print-ad :item="dr.from_location" titled="1"></print-ad>
                                    </td>
                                    <td>
                                        <print-ad :item="dr.to_location" titled="1"></print-ad>
                                    </td>
                                    <td>
                                        <ol-status :status="dr.load_status"></ol-status>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {request} from '../../../util/Request'
    import _ from 'lodash'
    import moment from "moment-timezone";

    export default {
        mixins: [],
        components: {},
        data() {
            return {
                inbound_loads: [],
                outbound_loads: [],
                verhicle_arrival_list: [],
                deliver_request: [],
                limit: 10
            }
        },
        mounted() {
            this.getInboundLoads();
            this.getOutboundLoads();
            this.getVehicleArrivals();
            this.getDeliveryRequests();
        },
        methods: {
            async getInboundLoads() {
                try {
                    const response = await request({
                        url: 'inbound/loads/dealer',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.inbound_loads = data;

                } catch (e) {

                }
            },
            async getOutboundLoads() {
                try {
                    const response = await request({
                        url: 'outbound/loads/dealer',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.outbound_loads = data;

                } catch (e) {

                }
            },
            async getVehicleArrivals() {
                try {
                    const response = await request({
                        url: 'dealer/to/dealer/loads/dealer',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.verhicle_arrival_list = data

                } catch (e) {

                }
            },
            async getDeliveryRequests() {
                try {
                    const response = await request({
                        url: '/orders/dealer/transport/requests',
                        params: {limit: this.limit}
                    })
                    const data = response.data
                    this.deliver_request = data
                } catch (e) {

                }
            },

        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
