<template>
    <div>
        <b-row>
            <b-col lg="3" md="3" sm="12" v-for="(cd, index) in entity" :key="index">
                <div class="card">
                    <div class="card-body position-relative overflow-hidden">
                        <div class="font-size-36 font-weight-bold text-dark mb-n2">{{cd.value}}</div>
                        <div class="text-uppercase text-muted">{{cd.title}}</div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="6" md="6" sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.users')}}</strong>
                        </div>
                        <div class="text-muted">{{$t('msc.lastTenUserRecords')}}</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.name')}}</th>
                                    <th>{{$t('column.phone')}}</th>
                                    <th>{{$t('column.email')}}</th>
                                    <th>{{$t('column.createdAt')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(cl, index) in users">
                                    <td>{{cl.name}}</td>
                                    <td>{{cl.phone}}</td>
                                    <td>{{cl.email}}</td>
                                    <td>{{$global.dateFormat(cl.created_at, 'd.M.Y H:m')}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col lg="6" md="6" sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.suppliers')}}</strong>
                        </div>
                        <div class="text-muted">{{$t('msc.lastTenSupplierRecords')}}</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.name')}}</th>
                                    <th>{{$t('column.phone')}}</th>
                                    <th>{{$t('column.email')}}</th>
                                    <th>{{$t('column.createdAt')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(cl, index) in suppliers">
                                    <td>{{cl.name}}</td>
                                    <td>{{cl.phone}}</td>
                                    <td>{{cl.email}}</td>
                                    <td>{{$global.dateFormat(cl.created_at, 'd.M.Y H:m')}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col lg="6" md="6" sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.manufacturers')}}</strong>
                        </div>
                        <div class="text-muted">{{$t('msc.lastTenManufacturerRecords')}}</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.name')}}</th>
                                    <th>{{$t('column.phone')}}</th>
                                    <th>{{$t('column.email')}}</th>
                                    <th>{{$t('column.createdAt')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(cl, index) in manufacturers">
                                    <td>{{cl.name}}</td>
                                    <td>{{cl.phone}}</td>
                                    <td>{{cl.email}}</td>
                                    <td>{{$global.dateFormat(cl.created_at, 'd.M.Y H:m')}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col lg="6" md="6" sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.drivers')}}</strong>
                        </div>
                        <div class="text-muted">{{$t('msc.lastTenDriverRecords')}}</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.name')}}</th>
                                    <th>{{$t('column.phone')}}</th>
                                    <th>{{$t('column.email')}}</th>
                                    <th>{{$t('column.createdAt')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(cl, index) in drivers">
                                    <td>{{cl.name}}</td>
                                    <td>{{cl.phone}}</td>
                                    <td>{{cl.email}}</td>
                                    <td>{{$global.dateFormat(cl.created_at, 'd.M.Y H:m')}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.weeklyForecastConfirmationRequestHistory')}}</strong>
                        </div>
                        <div class="text-muted">{{$t('msc.lastTenActivitiesRecords')}}</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.supplierCarrier')}}</th>
                                    <th>{{$t('column.weekNumber')}}</th>
                                    <th>{{$t('column.requestSentAt')}}</th>
                                    <th>{{$t('column.reminderSentAt')}}</th>
                                    <th>{{$t('column.acceptanceType')}}</th>
                                    <th>{{$t('column.acceptedAt')}}</th>
                                    <th>{{$t('column.acceptedBy')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(wfh, index) in weeklyForecastHistoryData">
                                    <td>{{wfh.price.supplier.name}}</td>
                                    <td>{{wfh.week_number}} {{wfh.next_week_number}}/{{wfh.next_week_year}}</td>
                                    <td>{{wfh.request_sent_at != '' ? $global.utcDateToLocalDate(wfh.request_sent_at) :
                                        ''}}
                                    </td>
                                    <td>{{wfh.reminder_sent_at != '' ? $global.utcDateToLocalDate(wfh.reminder_sent_at)
                                        : ''}}
                                    </td>
                                    <td v-if="wfh.accept_full > 0">{{$t('title.atnFullyConfirmed')}}</td>
                                    <td v-if="wfh.accept_contractual_difference > 0">
                                        {{$t('title.atnFullyAcceptedWithOffer')}}
                                    </td>
                                    <td v-if="wfh.accept_contractual > 0">{{$t('title.atnPartiallyAccepted')}}</td>
                                    <td v-if="wfh.cancel > 0">{{$t('title.atnRejected')}}</td>
                                    <td v-if="wfh.accept_full < 1 && wfh.accept_contractual_difference < 1 && wfh.accept_contractual < 1 && wfh.cancel < 1"></td>
                                    <td v-if="wfh.accept_full > 0">{{wfh.accepted_at != '' ?
                                        $global.utcDateToLocalDate(wfh.accepted_at) : ''}}
                                    </td>
                                    <td v-if="wfh.accept_contractual_difference > 0">
                                        {{wfh.accept_contractual_difference_at != '' ?
                                        $global.utcDateToLocalDate(wfh.accept_contractual_difference_at) : ''}}
                                    </td>
                                    <td v-if="wfh.accept_contractual > 0">{{wfh.accept_contractual_at != '' ?
                                        $global.utcDateToLocalDate(wfh.accept_contractual_at) : ''}}
                                    </td>
                                    <td v-if="wfh.cancel > 0">{{wfh.cancel_at != '' ?
                                        $global.utcDateToLocalDate(wfh.cancel_at) : ''}}
                                    </td>
                                    <td v-if="wfh.accept_full < 1 && wfh.accept_contractual_difference < 1 && wfh.accept_contractual < 1 && wfh.cancel < 1"></td>
                                    <td>{{wfh.accepted_by ? wfh.accepted_by : ''}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {request} from '../../../util/Request'
    import _ from 'lodash'
    import moment from "moment-timezone";

    export default {
        mixins: [],
        components: {},
        data() {
            return {
                entity: [],
                users: [],
                suppliers: [],
                manufacturers: [],
                drivers: [],
                weeklyForecastHistoryData: [],
                limit: 10
            }
        },
        mounted() {
            this.getDashboardData();
            this.getDrivers();
            this.getUsers();
            this.getSuppliers();
            this.getManufacturers();
            this.getWeeklyForecastHistory();
        },
        methods: {
            async getDashboardData() {
                try {
                    const response = await request({
                        url: '/dashboards/system',
                    })

                    const {data} = response.data
                    this.entity = data;

                } catch (e) {

                }
            },
            async getUsers() {
                try {
                    const response = await request({
                        url: 'users',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.users = data;

                } catch (e) {

                }
            },
            async getSuppliers() {
                try {
                    const response = await request({
                        url: 'suppliers',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.suppliers = data;

                } catch (e) {

                }
            },
            async getManufacturers() {
                try {
                    const response = await request({
                        url: 'manufacturers',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.manufacturers = data;

                } catch (e) {

                }
            },
            async getDrivers() {
                try {
                    const response = await request({
                        url: 'drivers',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.drivers = data;

                } catch (e) {

                }
            },
            async getWeeklyForecastHistory() {
                try {
                    const response = await request({
                        url: '/order/drafts/weekly/history',
                        params: {limit: this.limit}
                    })

                    const data = response.data

                    let count = 0;
                    _.map(data, (weeklyRequest, index) => {
                        _.map(weeklyRequest, (item, key) => {
                            if (item && item[0] && count < 10) {
                                let request_sent_at = _.find(item[0].history, {status: 1}).created_at
                                let reminder_sent_at = item[0]['second_reminder_sent'] > 0 && _.find(item[0].history, {status: 2}) ? _.find(item[0].history, {status: 2}).created_at : ''
                                if (item[0]['accept_full'] > 0) {
                                    item[0].accepted_at = _.find(item[0].history, {status: 3}) ? _.find(item[0].history, {status: 3}).created_at : ''
                                    item[0].accepted_by = item[0].accepted_by = _.find(item[0].history, {status: 3}) ? _.find(item[0].history, {status: 3}).author ? _.find(item[0].history, {status: 3}).author.name : '' : ''
                                }
                                if (item[0]['accept_contractual_difference'] > 0) {
                                    item[0].accept_contractual_difference_at = _.find(item[0].history, {status: 4}) ? _.find(item[0].history, {status: 4}).created_at : ''
                                    item[0].accepted_by = _.find(item[0].history, {status: 4}) ? _.find(item[0].history, {status: 4}).author ? _.find(item[0].history, {status: 4}).author.name : '' : ''
                                }
                                if (item[0]['accept_contractual'] > 0) {
                                    item[0].accept_contractual_at = _.find(item[0].history, {status: 5}) ? _.find(item[0].history, {status: 5}).created_at : ''
                                    item[0].accepted_by = _.find(item[0].history, {status: 5}) ? _.find(item[0].history, {status: 5}).author ? _.find(item[0].history, {status: 5}).author.name : '' : ''
                                }
                                if (item[0]['cancel'] > 0) {
                                    item[0].cancel_at = _.find(item[0].history, {status: 6}) ? _.find(item[0].history, {status: 6}).created_at : ''
                                    item[0].accepted_by = _.find(item[0].history, {status: 6}) ? _.find(item[0].history, {status: 6}).author ? _.find(item[0].history, {status: 5}).author.name : '' : ''
                                }
                                item[0].request_sent_at = request_sent_at;
                                item[0].reminder_sent_at = reminder_sent_at;
                                item[0].next_week_number = moment(item[0].start_week).add(1, 'w').isoWeek();
                                item[0].next_week_year = moment(item[0].start_week).add(1, 'w').isoWeekYear();
                                this.weeklyForecastHistoryData[count] = item[0];
                                count++;
                            }
                        })
                    })
                    if (this.weeklyForecastHistoryData.length > 0) {
                        this.weeklyForecastHistoryData.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1)
                    }
                } catch (e) {

                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
