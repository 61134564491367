<template>
    <div>
        <b-row>
            <b-col sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.inbound')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 Inbound Loads requests</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.loadNumber')}}</th>
                                    <th>{{$t('column.supplierCarrier')}}</th>
                                    <th>{{$t('column.fromLocation')}}</th>
                                    <th>{{$t('column.toLocation')}}</th>
                                    <th>{{$t('column.transportVehicle')}}</th>
                                    <th width="130">{{$t('column.loadingDate')}}</th>
                                    <th>{{$t('column.status')}}</th>
                                    <th>{{$t('column.createdAt')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(il, index) in inbound_loads">
                                    <td>{{il.load_number}}</td>
                                    <td>
                                        <a-avatar shape="square" :size="32" icon="user"
                                                  :title="il.supplier_carrier ? il.supplier_carrier.name : ''"
                                                  v-if="il.supplier_carrier && il.supplier_carrier.document"
                                                  :src="il.supplier_carrier.document.system_url+'/thumbnail/32x32/'+il.supplier_carrier.document.name2"
                                                  v-b-tooltip.hover />
                                        <a-avatar shape="square" :size="32" icon="user"
                                                  :title="il.supplier_carrier ? il.supplier_carrier.name : ''" v-else/>
                                    </td>
                                    <td>
                                        <print-ad :item="il.from_location" titled="1"></print-ad>
                                    </td>
                                    <td>
                                        <print-ad :item="il.to_location" titled="1"></print-ad>
                                    </td>
                                    <td>
                                        <span v-if="il.transport_vehicle && il.transport_vehicle.truck_plate_number"> {{ il.transport_vehicle.truck_plate_number }} </span>
                                        <span v-if="il.transport_vehicle && il.transport_vehicle.trailer_plate_number"> | {{ il.transport_vehicle.trailer_plate_number }} </span>
                                    </td>
                                    <td>
                                        <span v-if="il.from_estimated_loading_time">
                                        {{$global.utcDateToLocalDate(il.from_estimated_loading_time) }}
                                        </span>
                                        <span v-if="il.to_estimated_loading_time">
                                        <br>{{$global.utcDateToLocalDate(il.to_estimated_loading_time) }}
                                        </span>
                                    </td>
                                    <td>
                                        <ibl-status :status="il.status"></ibl-status>
                                    </td>
                                    <td>{{$global.utcDateToLocalDate(il.created_at)}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.outbound')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 Outbound Loads requests</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.loadNumber')}}</th>
                                    <th>{{$t('column.brand')}}</th>
                                    <th>{{$t('column.route')}}</th>
                                    <th>{{$t('column.transportVehicle')}}</th>
                                    <th width="130">{{$t('column.loadingDate')}}</th>
                                    <th>{{$t('column.status')}}</th>
                                    <th>{{$t('column.createdAt')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(ol, index) in outbound_loads">
                                    <td>{{ol.load_number}}</td>
                                    <td>
                                        <a-avatar shape="square" :size="32" icon="car"
                                                  :title="ol.outbound_load_orders[0] && ol.outbound_load_orders[0].order.brand ? ol.outbound_load_orders[0].order.brand.title : ''"
                                                  v-if="ol.outbound_load_orders[0].order.brand.logo && ol.outbound_load_orders[0].order.brand.logo.download_url"
                                                  :src="ol.outbound_load_orders[0].order.brand.logo.system_url+'/thumbnail/32x32/'+ol.outbound_load_orders[0].order.brand.logo.name2"/>
                                        <a-avatar shape="square" :size="32" icon="car"
                                                  :title="ol.outbound_load_orders[0] && ol.outbound_load_orders[0].order.brand ? ol.outbound_load_orders[0].order.brand.title : ''"
                                                  v-else/>

                                    </td>
                                    <td>{{ol.route ? ol.route.name : null}}</td>
                                    <td>
                                        <span v-if="ol.transport_vehicle && ol.transport_vehicle.truck_plate_number"> {{ ol.transport_vehicle.truck_plate_number }} </span>
                                        <span v-if="ol.transport_vehicle && ol.transport_vehicle.trailer_plate_number"> | {{ ol.transport_vehicle.trailer_plate_number }} </span>
                                    </td>
                                    <td>
                                    <span v-if="ol.estimated_loading_time">
                                        {{$global.utcDateToLocalDate(ol.estimated_loading_time) }}
                                    </span>
                                    </td>
                                    <td>
                                        <ibl-status :status="ol.status"></ibl-status>
                                    </td>
                                    <td>{{$global.utcDateToLocalDate(ol.created_at)}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.compound')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 Compound</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.orderId')}}</th>
                                    <th>{{$t('column.brand')}}</th>
                                    <th>{{$t('column.vin')}}</th>
                                    <th>{{$t('column.model')}}</th>
                                    <th>{{$t('column.orderedService')}}</th>
                                    <th>{{$t('column.serviceOrderDate')}}</th>
                                    <th>{{$t('column.status')}}</th>
                                    <th>{{$t('column.modifiedAt')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(ol, index) in compound_list">
                                    <td>{{ol.order_id}}</td>
                                    <td>
                                        <a-avatar shape="square" :size="32" icon="car" :title="ol.brand ? ol.brand.title : ''"
                                                  v-if="ol.brand.logo && ol.brand.logo.download_url"
                                                  :src="ol.brand.logo.system_url+'/thumbnail/32x32/'+ol.brand.logo.name2"/>
                                        <a-avatar shape="square" :size="32" icon="car" :title="ol.brand ? ol.brand.title : ''" v-else/>
                                    </td>
                                    <td>{{ol.vin_number}}</td>
                                    <td>{{ol.model ? ol.model.title : ""}}</td>
                                    <td>
                                        <span v-if="ol.additional_services" v-for="(service, index) in ol.additional_services">
                                            <span :title="service.additional_service.title">{{ index+1 }}. {{service.additional_service.title.length > 25 ? service.additional_service.title.substr(0, 24) + '...' : service.additional_service.title }}</span>
                                            <span v-if="service.is_rejected"> | {{ $t('title.rejected') }}</span>
                                            <span v-else>
                                               {{(!(!service.marked_done_at)) ? ' | ' + $t('title.done') : ' | ' + $t('title.pending') }}
                                             </span>
                                            <br>
                                        </span>
                                    </td>
                                    <td>
                                         <span v-if="ol.additional_services" v-for="(service, index) in ol.additional_services">
                                            <span :title="service.additional_service.title">{{ index+1 }}. {{ $global.utcDateToLocalDate(service.additional_service.created_at) }}</span>
                                            <br>
                                        </span>
                                    </td>
                                    <td>
                                        <ol-status :status="ol.load_status"></ol-status>
                                        <template v-if="ol.client_affected"><br>
                                            <b-badge v-if="ol.client_affected_priority === 1" variant="danger" :title="$t('title.clientAffected') + ' | ' + $t('title.high') + (ol.client_affected_description ? ' | ' + ol.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}} </b-badge>
                                            <b-badge v-if="ol.client_affected_priority === 2" variant="warning" :title="$t('title.clientAffected') + ' | ' + $t('title.medium') + (ol.client_affected_description ? ' | ' + ol.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}}</b-badge>
                                            <b-badge v-if="ol.client_affected_priority === 3" variant="secondary" :title="$t('title.clientAffected') + ' | ' + $t('title.low') + (ol.client_affected_description ? ' | ' + ol.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}}</b-badge>
                                        </template>
                                    </td>
                                    <td>{{$global.utcDateToLocalDate(ol.updated_at)}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.weeklyForecastConfirmationRequestHistory')}}</strong>
                        </div>
                        <div class="text-muted"></div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('column.supplierCarrier')}}</th>
                                    <th>{{$t('column.weekNumber')}}</th>
                                    <th>{{$t('column.requestSentAt')}}</th>
                                    <th>{{$t('column.reminderSentAt')}}</th>
                                    <th>{{$t('column.acceptanceType')}}</th>
                                    <th>{{$t('column.acceptedAt')}}</th>
                                    <th>{{$t('column.acceptedBy')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(wfh, index) in weeklyForecastHistoryData">
                                    <td>{{wfh.price.supplier.name}}</td>
                                    <td>{{wfh.week_number}} {{wfh.next_week_number}}/{{wfh.next_week_year}}</td>
                                    <td>{{wfh.request_sent_at != '' ? $global.utcDateToLocalDate(wfh.request_sent_at) : ''}}</td>
                                    <td>{{wfh.reminder_sent_at != '' ? $global.utcDateToLocalDate(wfh.reminder_sent_at) : ''}}</td>
                                    <td v-if="wfh.accept_full > 0">{{$t('title.atnFullyConfirmed')}}</td>
                                    <td v-if="wfh.accept_contractual_difference > 0">{{$t('title.atnFullyAcceptedWithOffer')}}</td>
                                    <td v-if="wfh.accept_contractual > 0">{{$t('title.atnPartiallyAccepted')}}</td>
                                    <td v-if="wfh.cancel > 0">{{$t('title.atnRejected')}}</td>
                                    <td v-if="wfh.accept_full < 1 && wfh.accept_contractual_difference < 1 && wfh.accept_contractual < 1 && wfh.cancel < 1"></td>
                                    <td v-if="wfh.accept_full > 0">{{wfh.accepted_at != '' ? $global.utcDateToLocalDate(wfh.accepted_at) : ''}}</td>
                                    <td v-if="wfh.accept_contractual_difference > 0">{{wfh.accept_contractual_difference_at != '' ? $global.utcDateToLocalDate(wfh.accept_contractual_difference_at) : ''}}</td>
                                    <td v-if="wfh.accept_contractual > 0">{{wfh.accept_contractual_at != '' ? $global.utcDateToLocalDate(wfh.accept_contractual_at) : ''}}</td>
                                    <td v-if="wfh.cancel > 0">{{wfh.cancel_at != '' ? $global.utcDateToLocalDate(wfh.cancel_at) : ''}}</td>
                                    <td v-if="wfh.accept_full < 1 && wfh.accept_contractual_difference < 1 && wfh.accept_contractual < 1 && wfh.cancel < 1"></td>
                                    <td>{{wfh.accepted_by ? wfh.accepted_by : ''}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {request} from '../../../util/Request'
    import _ from 'lodash'
    import moment from "moment-timezone";

    export default {
        mixins: [],
        components: {},
        data() {
            return {
                inbound_loads: [],
                outbound_loads: [],
                compound_list: [],
                weeklyForecastHistoryData: [],
                limit: 10
            }
        },
        mounted() {
            this.getInboundLoads();
            this.getOutboundLoads();
            this.getCompoundList();
            this.getWeeklyForecastHistory();
        },
        methods: {
            async getInboundLoads() {
                try {
                    const response = await request({
                        url: 'inbound/loads',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.inbound_loads = data;

                } catch (e) {

                }
            },
            async getOutboundLoads() {
                try {
                    const response = await request({
                        url: 'outbound/loads',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.outbound_loads = data;

                } catch (e) {

                }
            },
            async getCompoundList() {
                try {
                    const response = await request({
                        url: 'orders/compound/services',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.compound_list = data

                } catch (e) {

                }
            },
            async getWeeklyForecastHistory() {
                try {
                    const response = await request({
                        url: '/order/drafts/weekly/history',
                        params: {limit: this.limit}
                    })

                    const data = response.data

                    let count = 0;
                    _.map(data, (weeklyRequest, index) => {
                        _.map(weeklyRequest, (item, key) => {
                            if(item && item[0] && count < 10) {
                                let request_sent_at = _.find(item[0].history, {status: 1}).created_at
                                let reminder_sent_at = item[0]['second_reminder_sent'] > 0 && _.find(item[0].history, {status: 2}) ? _.find(item[0].history, {status: 2}).created_at : ''
                                if(item[0]['accept_full'] > 0) {
                                    item[0].accepted_at = _.find(item[0].history, {status: 3}) ? _.find(item[0].history, {status: 3}).created_at : ''
                                    item[0].accepted_by = _.find(item[0].history, {status: 3}) ? _.find(item[0].history, {status: 3}).author ? _.find(item[0].history, {status: 3}).author.name : '' : ''
                                }
                                if(item[0]['accept_contractual_difference'] > 0) {
                                    item[0].accept_contractual_difference_at = _.find(item[0].history, {status: 4}) ? _.find(item[0].history, {status: 4}).created_at : ''
                                    item[0].accepted_by = _.find(item[0].history, {status: 4}) ? _.find(item[0].history, {status: 4}).author ? _.find(item[0].history, {status: 4}).author.name : '' : ''
                                }
                                if(item[0]['accept_contractual'] > 0) {
                                    item[0].accept_contractual_at = _.find(item[0].history, {status: 5}) ? _.find(item[0].history, {status: 5}).created_at : ''
                                    item[0].accepted_by = _.find(item[0].history, {status: 5}) ? _.find(item[0].history, {status: 5}).author ? _.find(item[0].history, {status: 5}).author.name : '' : ''
                                }
                                if(item[0]['cancel'] > 0) {
                                    item[0].cancel_at = _.find(item[0].history, {status: 6}) ? _.find(item[0].history, {status: 6}).created_at : ''
                                    item[0].accepted_by = _.find(item[0].history, {status: 6}) ? _.find(item[0].history, {status: 6}).author ? _.find(item[0].history, {status: 5}).author.name : '' : ''
                                }
                                item[0].request_sent_at = request_sent_at;
                                item[0].reminder_sent_at = reminder_sent_at;
                                item[0].next_week_number = moment(item[0].start_week).add(1, 'w').isoWeek();
                                item[0].next_week_year = moment(item[0].start_week).add(1, 'w').isoWeekYear();
                                this.weeklyForecastHistoryData[count] = item[0];
                                count++;
                            }
                        })
                    })
                    if(this.weeklyForecastHistoryData.length > 0) {
                        this.weeklyForecastHistoryData.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1)
                    }
                } catch (e) {

                }
            },

        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
