<template>
    <div>
        <b-row>
            <b-col sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.inbound')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 Inbound Loads requests</div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{{$t('column.loadNumber')}}</th>
                                        <th>{{$t('column.fromLocation')}}</th>
                                        <th>{{$t('column.toLocation')}}</th>
                                        <th>{{$t('column.transportVehicle')}}</th>
                                        <th>{{$t('column.status')}}</th>
                                        <th>{{$t('column.qty')}}</th>
                                        <th>{{$t('column.createdAt')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(cl, index) in driver_inbound_loads">
                                        <td>{{cl.load_number}}</td>
                                        <td><print-ad titled="1" :item="cl.from_location"></print-ad></td>
                                        <td><print-ad titled="1" :item="cl.to_location"></print-ad></td>
                                        <td>
                                            <span v-if="cl.transport_vehicle && cl.transport_vehicle.truck_plate_number"> {{ cl.transport_vehicle.truck_plate_number }} </span>
                                            <span v-if="cl.transport_vehicle && cl.transport_vehicle.trailer_plate_number"> | {{ cl.transport_vehicle.trailer_plate_number }} </span>
                                        </td>
                                        <td><ibl-status :status="cl.status"></ibl-status></td>
                                        <td>{{cl.qty}}</td>
                                        <td>{{$global.utcDateToLocalDate(cl.created_at)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.outbound')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 Outbound Loads requests</div>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>{{$t('column.loadNumber')}}</th>
                                <th>{{$t('column.route')}}</th>
                                <th>{{$t('column.transportVehicle')}}</th>
                                <th>{{$t('column.numberOfOrders')}}</th>
                                <th>{{$t('column.status')}}</th>
                                <th>{{$t('column.createdAt')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(cl, index) in driver_outbound_loads">
                                <td>{{cl.load_number}}</td>
                                <td>{{cl.route ? cl.route.name : null}}</td>
                                <td>
                                    <span v-if="cl.transport_vehicle && cl.transport_vehicle.truck_plate_number"> {{ cl.transport_vehicle.truck_plate_number }} </span>
                                    <span v-if="cl.transport_vehicle && cl.transport_vehicle.trailer_plate_number"> | {{ cl.transport_vehicle.trailer_plate_number }} </span>
                                </td>
                                <td>{{cl.outbound_load_orders ? cl.outbound_load_orders.length : 0}}</td>
                                <td><ibl-status :status="cl.status"></ibl-status></td>
                                <td>{{$global.utcDateToLocalDate(cl.created_at)}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {request} from '../../../util/Request'

    export default {
        mixins: [],
        components: {},
        data() {
            return {
                driver_inbound_loads: [],
                driver_outbound_loads: [],
                limit: 10
            }
        },
        mounted() {
            this.getDriverInboundOrders();
            this.getDriverOutboundOrders();
        },
        methods: {
            async getDriverInboundOrders() {
                try {
                    const response = await request({
                        url: 'inbound/loads/driver',
                        params: {limit: this.limit}
                    })

                    const {data} = response
                    this.driver_inbound_loads = data;

                } catch (e) {

                }
            },
            async getDriverOutboundOrders() {
                try {
                    const response = await request({
                        url: 'outbound/loads/driver',
                        params: { limit: this.limit}
                    })

                    const {data} = response
                    this.driver_outbound_loads = data;

                } catch (e) {

                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
