<template>
    <div>
        <b-row>
            <b-col sm="12">
                <div class="card">
                    <div class="card-header">
                        <div class="cui__utils__heading mb-0">
                            <strong>{{$t('title.outbound')}}</strong>
                        </div>
                        <div class="text-muted">Last 10 Outbound Loads requests</div>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th>{{$t('column.loadNumber')}}</th>
                                <th>{{$t('column.route')}}</th>
                                <th>{{$t('column.transportVehicle')}}</th>
                                <th>{{$t('column.driver')}}</th>
                                <th>{{$t('column.numberOfOrders')}}</th>
                                <th>{{$t('column.status')}}</th>
                                <th>{{$t('column.createdAt')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(cl, index) in client_outbound_orders">
                                <td>{{cl.load_number}}</td>
                                <td>{{cl.route ? cl.route.name : null}}</td>
                                <td>
                                    <span v-if="cl.transport_vehicle && cl.transport_vehicle.truck_plate_number"> {{ cl.transport_vehicle.truck_plate_number }} </span>
                                    <span v-if="cl.transport_vehicle && cl.transport_vehicle.trailer_plate_number"> | {{ cl.transport_vehicle.trailer_plate_number }} </span>
                                </td>
                                <td>{{cl.driver ? cl.driver.name : null}}</td>
                                <td>{{cl.outbound_load_orders ? cl.outbound_load_orders.length : 0}}</td>
                                <td><ibl-status :status="cl.status"></ibl-status></td>
                                <td>{{$global.utcDateToLocalDate(cl.created_at)}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {request} from '../../../util/Request'

export default {
    mixins: [],
    components: {

    },
    data() {
        return {
            client_outbound_orders: [],
            limit: 10
        }
    },
    mounted() {
        this.getClientOutboundOrders();
    },
    methods: {
        async getClientOutboundOrders() {
            try {
                const response = await request({
                    url: 'outbound/loads/client',
                    params: { limit: this.limit}
                })

                const {data} = response
                this.client_outbound_orders = data;

            } catch (e) {

            }
        },
    },
    computed: {
        ...mapState([
            'global',
            'settings'
        ]),
    },
}
</script>
